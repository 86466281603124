import { AppBar as MuiAppBar, Toolbar, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import Profile from './profile';
import ButtonAccount from 'src/components/button/ButtonAccount';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${57}px)`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ open, toggle }) => {
  return (
    <AppBar
      position='fixed'
      open={open}
      sx={{ boxShadow: 'none', height: '64px' }}
    >
      <Toolbar
        sx={{
          padding: {
            xs: 0,
          },
          height: '64px',
        }}
      >
        <IconButton
          sx={{ color: 'white', marginLeft: '0.75rem' }}
          onClick={() => {
            toggle();
          }}
        >
          <MenuIcon
            sx={{
              transform: open ? 'rotate(0)' : 'rotate(-90deg)',
              transition: 'all .2s ease-out',
            }}
          />
        </IconButton>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mr: 1,
          }}
        >
          <ButtonAccount />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
