import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Avatar, Grid, ListItemIcon } from '@mui/material';
import { Box } from '@mui/system';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import Text from '../typography/Text';
import { useAuth } from 'src/context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'src/routes';
import { Person } from '@mui/icons-material';

const ButtonAccount = () => {
  const navigate = useNavigate();
  const { logout, userWorkspace } = useAuth();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!userWorkspace) {
      navigate(ROUTE_PATH.REGISTER);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id='account'
        size='small'
        color='primary'
        disableRipple
        sx={{
          pr: 1,
          borderRadius: '30px',
          background: (theme) => theme.palette.grey[100],
          '&:hover': {
            background: (theme) => theme.palette.grey[200],
            color: 'black',
          },
          textTransform: 'none',
        }}
        onClick={handleClick}
      >
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <Avatar
              src={userWorkspace?.profile?.thumbnail}
              sx={{
                border: (theme) => `1px solid ${theme.palette.grey[200]}`,
                width: 32,
                height: 32,
                background: (theme) =>
                  !userWorkspace?.profile?.key && theme.palette.primary.main,
              }}
            ></Avatar>
          </Grid>
          <Grid item>
            <Grid container alignItems='center'>
              <Grid item sx={{ maxWidth: '100px' }}>
                <Text variant='text2' noWrap>
                  {userWorkspace
                    ? `${userWorkspace?.User?.firstname} ${userWorkspace?.User?.lastname}`
                    : 'Sign In'}
                </Text>
              </Grid>
              {!!userWorkspace && (
                <Grid item>
                  <Box height='20px'>
                    <ArrowDropDownIcon fontSize='small' />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Button>
      <Menu
        id='account-menu'
        sx={{ marginTop: 1 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            navigate(ROUTE_PATH.PROFILE);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          {t('profile')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();

            handleClose();
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ButtonAccount;
