import login from './login.json';
import common from './common.json';
import authenticate from './authenticate.json';
import user from './user.json';
import rolePermission from './role-permission.json';
import callLog from './call-log.json';
import cityAndProvince from './city-and-province.json';
import shiftManagement from './shift-management.json';

export const TRANSLATIONS_KH = {
  ...login,
  ...authenticate,
  ...user,
  ...common,
  ...rolePermission,
  ...callLog,
  ...cityAndProvince,
  ...shiftManagement,
};
