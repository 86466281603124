import React, { useState } from 'react';
import { Box, Backdrop, CircularProgress } from '@mui/material';

import useUI from 'src/hooks/useUI';
import Navbar from './navbar';
import Drawer from './drawer';

const DashboardLayout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const toggle = () => {
    setOpen((prev) => !prev);
  };

  // Check for some route that doesn't need layout

  const { isLoading } = useUI();

  return (
    <Box display='flex' height='100vh' overflow='hidden'>
      <Backdrop sx={{ zIndex: 1202 }} open={isLoading}>
        <CircularProgress color='primary' thickness={5} size={70} />
      </Backdrop>

      <Navbar open={open} toggle={toggle} />
      <Drawer open={open} />
      <Box component='main' sx={{ flexGrow: 1, p: 3, overflow: 'overlay' }}>
        {/* {!noLayoutNeeded && <Box width="100%" height="64px" />} */}
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
