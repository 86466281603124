import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import globalStyle from './global';

export const primary = {
  // lighter: '#02b9ca',
  // light: '#02a2b1',
  main: '#2980B9',
  // highlight: '#03e8fc',
  dark: 'rgb(35, 110, 160)',
  darker: 'rgb(31, 100, 146)',
};

let theme = createTheme({
  palette: {
    primary,
    secondary: {
      main: '#092441',
      light: '#f1f1f1',
    },
    error: {
      main: '#E4045E',
    },
    drawer: {
      background: primary.main,
      hover: primary.light,
      selected: primary.darker,
      subSelected: primary.dark,
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'KhmerOS Siemreap',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
        elevation2: {
          boxShadow:
            '1px 1px 18px 1px rgb(0 0 0 / 10%), -1px 0px 4px 0px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
      },
    },
  },
});

//overwrite globalStyle
theme.components.MuiCssBaseline.styleOverrides = globalStyle(theme);

theme = responsiveFontSizes(theme);

export default theme;
