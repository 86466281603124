import { Navigate, Outlet } from 'react-router';
import DashboardLayout from 'src/layouts/dashboard';
import PrivateRoute from 'src/components/private-route';
import React, { lazy, Suspense } from 'react';
import { LinearProgress } from '@mui/material';
import { AccessControlRoute } from 'src/components/access-control';

// lazy loading
const LoginPage = lazy(() => import('src/pages/Auth/Login'));
const RegisterPage = lazy(() => import('src/pages/Auth/register'));
const ForgotPasswordPage = lazy(() => import('src/pages/Auth/Forgot-Password'));
const CallLogListPage = lazy(() => import('src/pages/call-log-listing'));
const CallLogListAdminPage = lazy(() =>
  import('src/pages/call-log-listing-admin'),
);
const CallLogDetailPage = lazy(() => import('src/pages/call-log-detail'));
const UserPage = lazy(() => import('src/pages/setting/user'));
const RolePermissionSettingPage = lazy(() =>
  import('src/pages/setting/role-permission'),
);
const CategoryManagementPage = lazy(() =>
  import('src/pages/setting/category-management'),
);
const ProfilePage = lazy(() => import('src/pages/setting/profile'));
const ShiftListPage = lazy(() => import('src/pages/setting/shift-management'));
const CreateShiftPage = lazy(() =>
  import('src/pages/setting/shift-management/create'),
);

export const ROUTE_PATH = {
  REGISTER: '/auth/register',
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  DASHBOARD: '/dashboard/form',
  PROFILE: '/dashboard/setting/profile',
};

const ROUTES_PATH = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <PrivateRoute auth={true} redirect='/dashboard/form'>
            <Suspense fallback={<LinearProgress />}>
              <LoginPage />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'register',
        element: (
          <PrivateRoute auth={true} redirect={ROUTE_PATH.DASHBOARD}>
            <Suspense fallback={<LinearProgress />}>
              <RegisterPage />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'invite/:token',
        element: (
          <Suspense fallback={<LinearProgress />}>
            <RegisterPage />
          </Suspense>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <PrivateRoute auth={true} redirect={ROUTE_PATH.DASHBOARD}>
            <Suspense fallback={<LinearProgress />}>
              <ForgotPasswordPage />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  // PRIVATE ROUTE
  {
    path: 'dashboard',
    element: (
      <PrivateRoute auth={false} redirect='/login'>
        <DashboardLayout>
          <Suspense fallback={<LinearProgress />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </PrivateRoute>
    ),
    children: [
      { index: true, element: null },
      {
        path: 'call-log',
        element: (
          <AccessControlRoute execute='view' feature='call-log'>
            <CallLogListPage />
          </AccessControlRoute>
        ),
      },
      {
        path: 'call-log/all',
        element: (
          <AccessControlRoute execute='view-all' feature='call-log'>
            <CallLogListAdminPage />
          </AccessControlRoute>
        ),
      },
      {
        path: 'call-log/detail/:id',
        element: (
          <AccessControlRoute execute='view' feature='call-log'>
            <CallLogDetailPage />
          </AccessControlRoute>
        ),
      },
      {
        path: 'setting',
        children: [
          {
            path: 'category',
            element: (
              <AccessControlRoute execute='view' feature='role-permission'>
                <CategoryManagementPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'role-permission',
            element: (
              <AccessControlRoute execute='view' feature='role-permission'>
                <RolePermissionSettingPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'user',
            element: (
              <AccessControlRoute execute='view' feature='user'>
                <UserPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'shift-management',
            element: (
              <AccessControlRoute execute='view' feature='shift-management'>
                <ShiftListPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'shift-management/:id',
            element: (
              <AccessControlRoute execute='create' feature='shift-management'>
                <CreateShiftPage />
              </AccessControlRoute>
            ),
          },
          {
            path: 'profile',
            element: <ProfilePage />,
          },
        ],
      },
      { path: '*', element: <Navigate to='/dashboard' /> },
    ],
  },
  { path: '*', element: <Navigate to='auth/login' /> },
];

export default ROUTES_PATH;
