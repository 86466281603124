import SettingsIcon from '@mui/icons-material/Settings';
import TtyIcon from '@mui/icons-material/Tty';

/* follow react router routes style (Required AccessControl Component)
 menuConfig consist of 2 element 
  - basePath <String> : root path of every path - ex: dashboard
  - menu <Array Object> : list of path 
        - label <String> : Name of the label
        - path <String> : path url ( will take basePath combine with current path)
        - index <Boolean> : if it is root of the path. if your basePath is dashboard and you want to use /dashboard as your path (index:true)
        - exact <Boolean> : active only if the current url match exact path
        - icon <React Component> : icon to display
        - access Array[execute,feature] : hide or show tab base on role check with accessControl 
        - children <Array Object> : if menu have submenu have access to all object above except icon and children
*/
// menu Config translation use namespace so it wont affect with other (menu.json)
const menuConfig = {
  basePath: 'dashboard',
  menu: [
    {
      label: 'call-log',
      path: 'call-log',
      icon: TtyIcon,
      access: ['view', 'call-log'],
      children: [
        {
          label: 'call-log-listing',
          index: true,
          access: ['view', 'call-log'],
          exact: true,
        },
        {
          label: 'call-log-listing-2',
          path: 'all',
          access: ['view-all', 'call-log'],
        },
      ],
    },
    {
      label: 'setting',
      icon: SettingsIcon,
      path: 'setting',
      children: [
        {
          label: 'user',
          path: 'user',
          access: ['view', 'user'],
        },
        {
          label: 'shift-management',
          path: 'shift-management',
          access: ['view', 'shift-management'],
        },
        {
          label: 'category-management',
          path: 'category',
          access: ['view', 'category'],
        },
        {
          label: 'role-permission',
          path: 'role-permission',
          access: ['view', 'role-permission'],
        },
      ],
    },
  ],
};

export default menuConfig;
